"use client";
import Image from "next/image";
import Icon from "../Icon";
import { Fragment } from "react";
import Link from "next/link";

const Footer = () => {
    const OurStory = [
        {
            name: "Our Story",
            link: "/about-us"
        },
        {
            name: "Location",
            link: "/restaurants"
        },
        {
            name: "News",
            link: "/news-listings"
        },
        {
            name: "Faq",
            link: "/faq"
        },
        {
            name: "Happiness Card",
            link: "/vouchers"
        },
        {
            name: "Investor Relations",
            link: "/investors"
        },
        {
            name: "Careers",
            link: "/careers"
        },
        {
            name: "BBQN Partnership",
            link: "/webform/barbeque-nation-partnership"
        },
    ]
    const Locations = [
        {
            name: "Blogs",
            link: "/blogs"
        },
        {
            name: "Corporate Enquiry",
            link: "/corporate-enquiry"
        },
        {
            name: "What’s On BBQN",
            link: "/promotion"
        },
        {
            name: "Contact Us",
            link: "/contact-us"
        },
        {
            name: "Nutrition Information",
            link: "https://bn.barbequenation.com/nutrition_value",
        },
    ];


    return (
        <footer className={`pt-10 pb-24 md:pb-[50px] md:py-[50px] text-white bg-primary-1`}>
            <div className="container">
                <div className="md:flex block">
                    <div className="w-full md:max-w-[calc(100%-335px)] md:pb-[60px] pb-0 md:border-r md:border-gray-2">
                        <div className="flex items-center gap-5">
                            <Image loading='lazy' src="/icons/logo-black.svg" alt="Barbeque Nation" width={64} height={46} className='max-w-[107px] w-full md:max-w-[64px] block' />
                            <p className="font-inter-16-regular md:hidden block  max-[767px]:text-[14px] !text-[#1E1D1D]">Experience the vibrant atmosphere of our restaurants. Immerse yourself in the aroma of grilled delicacies.</p>
                        </div>
                        {/* Social Links */}
                        <div className="flex mt-[20px] md:hidden  items-center gap-7">
                            <Link href="https://www.facebook.com/BarbequeNation/" target="_blank" aria-label="Barbeque Nation Facebook Profile Link">
                                <Icon name='Facebook' className="rounded-full hover:scale-110 hover:rotate-6 transition-transform duration-300 ease-out" />
                            </Link>
                            <Link href="https://www.instagram.com/barbequenation/" target="_blank" aria-label="Barbeque Nation Instagram Profile Link">
                                <Icon name='insta' className="rounded-full hover:scale-110 hover:rotate-6 transition-transform duration-300 ease-out" />
                            </Link>
                            <Link href="https://www.twitter.com/barbequenation" target="_blank" aria-label="Barbeque Nation Twitter/X Profile Link">
                                <Icon name='twitter' className="rounded-full hover:scale-110 hover:rotate-6 transition-transform duration-300 ease-out" />
                            </Link>
                            <Link href="https://www.youtube.com/channel/UCA-ACmkbdLdSePOPckbq18A" target="_blank" aria-label="Barbeque Nation Youtube Channel Link">
                                <Icon name='youtube' className="rounded-full hover:scale-110 hover:rotate-6 transition-transform duration-300 ease-out" />
                            </Link>
                        </div>

                        {/* Footer Links */}
                        <div className="grid mt-[40px] md:gap-0 gap-4 grid-cols-2">
                            <div className="">
                                {/* <h3 className="md:text-xl text-lg leading-6 md:mb-[56px] mb-5 font-poppins font-medium text-[#1E1D1D]">Our Story</h3> */}
                                <ul className="grid lg:grid-cols-2  gap-x-2 md:gap-y-7 gap-y-[17px]">
                                    {
                                        OurStory?.map((item, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    <li>
                                                        <Link href={item?.link} className="hover:text-primary-5 duration-300 font-inter text-sm font-normal leading-[22.4px] text-gray-5">{item?.name}</Link>
                                                    </li>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="">
                                {/* <h3 className="md:text-xl text-lg leading-6 mb-5 md:mb-[56px] font-poppins font-medium text-[#1E1D1D]">Locations</h3> */}
                                <ul className="grid lg:grid-cols-2 gap-x-2 md:gap-y-7 gap-y-[17px]">
                                    {
                                        Locations?.map((item, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    <li>
                                                        <Link href={item?.link} className="hover:text-primary-5 duration-300 font-inter text-sm font-normal leading-[22.4px] text-gray-5">{item?.name}</Link>
                                                    </li>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>

                    </div>

                    <div className="w-full md:max-w-[335px] md:pl-[52px] ">
                        <div className="md:flex hidden  mt-[20px]  items-center gap-7 mb-[40px]">
                            <Link href="https://www.facebook.com/BarbequeNation/" target="_blank" aria-label="Barbeque Nation Facebook Profile Link">
                                <Icon name='Facebook' className="rounded-full hover:scale-110 hover:rotate-6 transition-transform duration-300 ease-out" />
                            </Link>
                            <Link href="https://www.instagram.com/barbequenation/" target="_blank" aria-label="Barbeque Nation Instagram Profile Link">
                                <Icon name='insta' className="rounded-full hover:scale-110 hover:rotate-6 transition-transform duration-300 ease-out" />
                            </Link>
                            <Link href="https://www.twitter.com/barbequenation" target="_blank" aria-label="Barbeque Nation Twitter/X Profile Link">
                                <Icon name='twitter' className="rounded-full hover:scale-110 hover:rotate-6 transition-transform duration-300 ease-out" />
                            </Link>
                            <Link href="https://www.youtube.com/channel/UCA-ACmkbdLdSePOPckbq18A" target="_blank" aria-label="Barbeque Nation Youtube Channel Link">
                                <Icon name='youtube' className="rounded-full hover:scale-110 hover:rotate-6 transition-transform duration-300 ease-out" />
                            </Link>
                        </div>
                        <p className="font-inter-16-regular md:block hidden  max-[767px]:text-[14px] !text-[#1E1D1D]">Experience the vibrant atmosphere of our restaurants. Immerse yourself in the aroma of grilled delicacies.</p>
                        <div className="flex mt-5 md:mt-0 md:flex-col md:gap-0 gap-3 md:justify-start justify-center lg:mb-5">
                            <Link target="_blank" href="https://play.google.com/store/apps/details?id=com.jamhub.barbeque&hl=en_IN&pli=1" aria-label="Barbeque Nation Android App Link">
                                <Image loading='lazy' src="/images/googleplay.png" className="max-[767px]:w-full md:mt-[71px]" alt="Barbeque Nation" width={142} height={43} />
                            </Link>
                            <Link target='_blank' href="https://apps.apple.com/in/app/barbeque-nation/id1080269411" aria-label="Barbeque Nation IOS App Link">
                                <Image loading='lazy' src="/images/appstore.png" className="max-[767px]:w-full md:mt-3" alt="Barbeque Nation" width={142} height={43} />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="pt-[15px] md:border-t border-gray-2 flex items-center justify-center md:justify-between md:flex-row flex-col gap-3">
                    <p className="font-inter text-xs text-[#1E1D1D] max-[767px]:text-center">©All Rights Reserved by BBQ Nations</p>
                    <div className="flex items-center gap-2 md:gap-6">
                        <Link href="/privacy-policy" className="hover:text-primary-5 duration-300 font-inter-16-regular !text-xs" aria-label="Privacy Policy Link">Privacy Policy</Link>
                        <Link href="/terms-conditions" className="hover:text-primary-5 duration-300 font-inter-16-regular  !text-xs" aria-label="Term and Conditions Link">Term & Conditions</Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;