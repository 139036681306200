import { SavedBranchInfo, additionalCharge, BranchesState, SlotsData, collectedBookingDetails, Slot } from '@/types/next';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

/**
 * Parses saved booking data from localStorage.
 * 
 * @returns {SavedBranchInfo | null} The parsed saved data or null if it doesn't exist or fails to parse.
 */
function parseSavedData(): SavedBranchInfo | null {
  try {
    if (typeof window === 'undefined') return null; // Ensure it's running in a browser environment
    const data = localStorage.getItem('bwf');
    if (data === undefined || data === null) return null;

    return JSON.parse(data);
  } catch (error) {
    console.error('Error parsing saved data:', error);
    return null;
  }
}

/**
 * Initial state for the slots slice, includes booking details, estimates, and data fetching state.
 */
const initialState: BranchesState = {
  data: [],
  loadingNearbyBranches: false,
  loadingSlots: false,
  errorNearbyBranches: null,
  errorSlots: null,
  slotsData: null,
  tableBookingEstimate: {
    subtotal: 0,
    cgst: 0,
    sgst: 0,
    serviceCharge: 0,
    itemTotal: 0,
    total: 0,
  },
  collectedBookingDetails: {
    transaction_type: 'BOOKING',
    voucher_type: 'GC',
    packs: 0,
    reservation_date: '',
    branch_id: 0,
    amount: 0,
    slot_id: 0,
    currency: 'INR',
    booking_details: [],
    loyalty_points: 0,
    voucher_details: [],
  },
  saved: parseSavedData(), // Fetch saved booking data from localStorage
};

/**
 * Async thunk for fetching slots from the API.
 * 
 * @param {Object} params - The parameters for the slot API request.
 * @param {string} params.reservation_date - The reservation date for which to fetch slots.
 * @param {number} params.branch_id - The branch ID for which to fetch available slots.
 * @param {string} [params.dinner_type] - Optional dinner type for slot filtering.
 * 
 * @returns {Promise<SlotsData>} The fetched slots data or an error if the request fails.
 */

const determineDiningType = (time: string): string => {
  const [hours] = time.split(':').map(Number);
  return hours < 17 ? 'LUNCH' : 'DINNER';
};

/**
 * Helper function to parse the remark field and generate slots (for nationalityType: 1).
 */
const parseRemarkToSlots = (remark: string, availableCapacity: number, slotStop: string): Slot[] => {
  const slotTimes = remark.split('|');
  const slots: Slot[] = [];

  slotTimes.forEach((startTime, index) => {
    const diningType = determineDiningType(startTime);
    slots.push({
      slot_id: index + 1, // Generate a unique slot_id since it's not provided
      slot_desc: `Slot ${index + 1}`,
      dining_type: diningType,
      slot_start_time: `${startTime}:00`, // Add seconds for consistency
      slot_stop_time: slotStop, // Use the slotStop as the end time for all slots
      total_capacity: availableCapacity, // Use availableCapacity as total_capacity
      available_capacity: availableCapacity,
      flexi_slot: true, // Default value
      early_bird: false, // Default value
      late_bird: false, // Default value
    });
  });

  return slots;
};


export const fetchSlots = createAsyncThunk(
  'slots/fetchSlots',
  async ({ reservation_date, branch_id, dinner_type, nationalityType }: { reservation_date: string, branch_id: string, dinner_type?: string, nationalityType: number }, { rejectWithValue }) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let response;
      let url;
      let body;
      if (nationalityType === 0) {
        // nationalityType: 1 (India Branch Slots)
        url = "/api/v1/slots";
        body = JSON.stringify({
          reservation_date,
          branch_id,
          dinner_type,
        });

        response = await fetch(url, {
          method: "POST",
          headers: myHeaders,
          body,
          redirect: "follow" as RequestRedirect,
        });
      } else if (nationalityType === 1) {
       
        url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/v1/international/branch-slots`;
        body = JSON.stringify({
          reservationDate: reservation_date,
          branchId: `${branch_id.toString()}`, // Convert branch_id to string as per API requirement
          slotTime: "00:00", // Default slot time as per API payload
          utcTimezone: false, // Default as per API payload
        });

        response = await fetch(url, {
          method: "POST",
          headers: myHeaders,
          body,
          redirect: "follow" as RequestRedirect,
        });
      } else {
        throw new Error("Invalid nationalityType provided.");
      }

      // Handle non-OK response
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData?.error.includes('Blocked by Cloudflare'))
          toast.warn(`${errorData?.error}`)
        return rejectWithValue(errorData);
      }

      // Parse the slots data from the response
      const result = await response.json();
      if (nationalityType === 0) {
        return result?.results; // For nationalityType: 0, return as-is
      } else {
        // For nationalityType: 1, parse and normalize the response
        const { data } = result?.results;
        if (!data || !data.remark) {
          return {
            preferred_branch: {
              slots_available: [],
            },
          };
        }

        const slots = parseRemarkToSlots(data.remark, data.availableCapacity, data.slotStop);
        return {
          preferred_branch: {
            slots_available: slots,
          },
        };
      }

    } catch (error: any) {
      // Return the error message if an error occurs
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Redux slice for managing slots and booking estimates.
 * Handles fetching slots, storing booking estimates, and collected booking details.
 */
const slotsSlice = createSlice({
  name: 'slots',
  initialState,
  reducers: {
    /**
     * Updates the booking estimate in the state.
     * 
     * @param {BranchesState} state - The current state of the slice.
     * @param {PayloadAction<additionalCharge>} action - The action containing the new booking estimate.
     */
    bookingEstimate: (state, action: PayloadAction<additionalCharge>) => {
      state.tableBookingEstimate = action.payload;
    },

    /**
     * Updates the collected booking details in the state.
     * 
     * @param {BranchesState} state - The current state of the slice.
     * @param {PayloadAction<collectedBookingDetails>} action - The action containing the new collected booking details.
     */
    collectBookingDetails: (state, action: PayloadAction<collectedBookingDetails>) => {
      state.collectedBookingDetails = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      /**
       * Handles the pending state when fetching slots.
       * 
       * @param {BranchesState} state - The current state of the slice.
       */
      .addCase(fetchSlots.pending, (state) => {
        state.loadingSlots = true;
        state.errorSlots = null;
      })

      /**
       * Handles the fulfilled state when slots are successfully fetched.
       * 
       * @param {BranchesState} state - The current state of the slice.
       * @param {PayloadAction<SlotsData>} action - The action containing the fetched slots data.
       */
      .addCase(fetchSlots.fulfilled, (state, action: PayloadAction<SlotsData>) => {
        state.loadingSlots = false;
        state.slotsData = action.payload;
      })

      /**
       * Handles the rejected state when fetching slots fails.
       * 
       * @param {BranchesState} state - The current state of the slice.
       * @param {PayloadAction<any>} action - The action containing the error message.
       */
      .addCase(fetchSlots.rejected, (state, action: PayloadAction<any>) => {
        state.loadingSlots = false;
        state.errorSlots = action.payload;
      });
  },
});

/** Export actions for booking estimate and collected booking details */
export const { bookingEstimate, collectBookingDetails } = slotsSlice.actions;

/** Export the reducer to be used in the store */
export default slotsSlice.reducer;