import { Fragment } from "react"

const HeaderLinks = () =>{
    const BarbequeNation = [
        {
            name:"Home",
            link:"/"
        },
        {
            name:"What’s On BBQN",
            link:"/promotion"
        },
        {
            name:"Delivery/Takeaway",
            link:"/ubq-delivery"
        },
        {
            name:"Restaurants",
            link:"/restaurants"
        },
        {
            name:"Happiness Cards",
            link:"/vouchers"
        },
        {
            name:"Catering",
            link:"/catering"
        },
    ]
    const About = [
        {
            name:"About Us",
            link:"/about-us"
        },
        {
            name:"Blogs",
            link:"/blogs"
        },
        {
            name:"Smiles",
            link:"/smiles"
        },
        {
            name:"News",
            link:"/news-listings"
        },
        {
            name:"Nutrition Information",
            link:"https://bn.barbequenation.com/nutrition_value"
        },
    ]
    const Others =[
        {
            name:"Contact Us",
            link:"/contact-us"
        },
        {
            name:"FAQ",
            link:"/faq"
        },
        {
            name:"Corporate Enquiry",
            link:"/corporate-enquiry"
        },
        {
            name:"Investor Relations",
            link:"/investors"
        },
        {
            name:"Barbeque Nation Partnership",
            link:"/webform/barbeque-nation-partnership"
        },
    ]
    return(
        <>
            <div className="md:mt-[26px]">
                <h3 className="text-[24px] leading-7 mb-[13px] font-poppins font-medium text-[#21272A]">Barbeque Nation</h3>
                <ul className="grid grid-cols-2 gap-[15px] pt-[14px] border-t border-gray-2">
                    {
                       BarbequeNation?.map((item,index)=>{
                        return(
                            <Fragment key={index}>
                                <li>
                                    <a href={item?.link} className="hover:text-primary-5 duration-300 font-inter-16-regular">{item?.name}</a>
                                </li>
                            </Fragment>
                        )
                       })
                    }
                </ul>
            </div>
            <div className="md:mt-[60px] mt-10">
                <h3 className="text-[24px] leading-7 mb-[13px] font-poppins font-medium text-[#21272A]">About</h3>
                <ul className="grid grid-cols-2 gap-[15px] pt-[14px] border-t border-gray-2">
                    {
                       About?.map((item,index)=>{
                        return(
                            <Fragment key={index}>
                                <li>
                                    <a href={item?.link} className="hover:text-primary-5 duration-300 font-inter-16-regular">{item?.name}</a>
                                </li>
                            </Fragment>
                        )
                       })
                    }
                </ul>
            </div>
            <div className="md:mt-[60px] mt-10">
                <h3 className="text-[24px] leading-7 mb-[13px] font-poppins font-medium text-[#21272A]">Others</h3>
                <ul className="grid grid-cols-2 gap-[15px] pt-[14px] border-t border-gray-2">
                    {
                       Others?.map((item,index)=>{
                        return(
                            <Fragment key={index}>
                                <li>
                                    <a href={item?.link} className="hover:text-primary-5 duration-300 font-inter-16-regular">{item?.name}</a>
                                </li>
                            </Fragment>
                        )
                       })
                    }
                </ul>
            </div>
        </>
    )
}

export default HeaderLinks;