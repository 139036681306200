// store/slices/locationSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LocationState {
  latitude: number | null;
  longitude: number | null;
  country: string | null;
  countryCode: string | null;
  error: string | null;
}

const initialState: LocationState = {
  latitude: null,
  longitude: null,
  country: null,
  countryCode: null,
  error: null,
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocation: (
      state,
      action: PayloadAction<{
        latitude: number;
        longitude: number;
        country: string;
        countryCode: string;
      }>
    ) => {
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
      state.country = action.payload.country;
      state.countryCode = action.payload.countryCode;
      state.error = null;
    },
    setLocationError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.latitude = null;
      state.longitude = null;
      state.country = null;
      state.countryCode = null;
    },
  },
});

export const { setLocation, setLocationError } = locationSlice.actions;
export default locationSlice.reducer;