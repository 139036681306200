import(/* webpackMode: "eager" */ "/var/www/html/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/html/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/node_modules/react-loading-skeleton/dist/index.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/var/www/html/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/html/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/var/www/html/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/var/www/html/src/app/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/src/app/SessionProvider.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/html/src/app/UtmProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BodyClassSetter"] */ "/var/www/html/src/components/BodyClassSetter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/src/components/headerFooter/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/src/components/headerFooter/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/src/components/LocationFetcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/var/www/html/src/components/web-vitals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/src/hooks/AppStorageHandler.tsx");
