// components/LocationFetcher.tsx
"use client";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLocation, setLocationError } from "@/store/slices/locationSlice";
import { getCountryData } from "@/lib/utils";
import useGeolocation from "@/hooks/useGeolocation";

export default function LocationFetcher() {
  const dispatch = useDispatch();
  const { location, requestLocation } = useGeolocation();

  useEffect(() => {
    requestLocation()
      .then(() => {})
      .catch((err) => {
        dispatch(setLocationError(err));
      });
  }, [requestLocation, dispatch]);

  useEffect(() => {
    const fetchCountryData = async () => {
      if (location.latitude && location.longitude) {
        try {
          const { country, countryCode } = await getCountryData(
            location.latitude,
            location.longitude
          );
          dispatch(
            setLocation({
              latitude: location.latitude,
              longitude: location.longitude,
              country,
              countryCode,
            })
          );
        } catch (err) {
          dispatch(setLocationError("Could not fetch country data"));
        }
      } else if (location.geoError) {
        dispatch(setLocationError(location.geoError));
      }
    };

    fetchCountryData();
  }, [location.latitude, location.longitude, location.geoError, dispatch]);

  return null;
}